import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import styled from 'styled-components';

// Styled components


const StyledFooter = styled.footer`
  padding: 20px 0;
  background-color: #333333;
  color: #efefef;
  .nav-link {
      color: #e6e6e6;
      text-decoration: none;
      margin:0 0px;
      &:hover {
        color: #ff1312;
      }
    }
`;

const StyledContainer = styled(Container)`
  max-width: 70%;
  margin: auto;
  padding: 0 15px;
`;

const FooterMenu = () => {
  return (
    <StyledFooter className="mt-auto py-3">
      <StyledContainer>
        <Row>
        <Col md={3}>
            <h5>Follow Us</h5>
            <p>Stay updated with the latest news and updates by following us on social media.</p>
          </Col>
          <Col md={3}>
            <h5>Contact Us</h5>
            <p>Have questions or suggestions? Reach out to the developer by email.</p>
            <a href="mailto:contact@textitsearchit.com">contact@textitsearchit.com</a>          
            </Col>
          <Col md={3}>
            <h5>Our Parent Company SLEKE.</h5>
            <p>Stay updated with the latest news/updates for the newly released SLEKE. Phone by following us on our website and Reddit or become a tester and join our Discord server.</p>
          </Col>
            <Col md={3}>
                <h5>Tisi Legal Documents</h5>
                <p>Please read our terms of service and privacy policy.</p>
                <Nav.Link  href="/terms" className="nav-link">Terms of Service</Nav.Link>
                <Nav.Link  href="/privacy" className="nav-link">Privacy Policy</Nav.Link>
            </Col>
        </Row>
      </StyledContainer>
      
    </StyledFooter>
  );
};

export default FooterMenu;