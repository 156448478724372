import React from 'react';
import './index.css';
import AppWithAuthenticator from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

const root = document.getElementById('root');
const rootElement = createRoot(root);
rootElement.render( <React.StrictMode>
    <BrowserRouter>
      <AppWithAuthenticator />
    </BrowserRouter>
  </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
