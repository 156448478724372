import styled from 'styled-components';
import { Stack, Button, Row, Col, Link } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { Link as RouterLink } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import TisiCapabilities from '../assets/tisi-capabilities.png';
import HugeResults from '../assets/huge-search-results.png';
import AiCircle from '../assets/AI-crop-circle.png';
import TisiFlipPhone from '../assets/flipphone-tisi.png';
import PerplexityChat from './PerplexityChat';

const StyledBanner = styled.div`
  background-color: #e6e6e6; /* Lighter shade for a professional look */
  padding: 40px 200px;
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const StyledHeading = styled.h1`
  color: #333;
  margin-bottom: 20px;
  font-size: 3rem; /* Larger for emphasis */
  text-align: center
`;

const StyledSubHeading = styled.h2`
  color: #555; /* Slightly darker for contrast */
  margin-bottom: 15px;
  font-size: 2rem; /* Increased size for readability */
  text-align: center;
`;

const StyledCard = styled(Card)`
  margin-bottom: 50px;
  background-color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
`;

const StyledCardBody = styled(Card.Body)`
  padding: 20px;
  color: #efefef;
   marginBottom: '50px' ;
`;

const StyledCardTitle = styled(Card.Title)`
  color: #ff1312;
  font-size: 1.5rem;
`;

const StyledButton = styled(Button)`
  margin: 40px; /* Increased margin to space buttons away from cards */
  background-color: #333; /* Updated background color */
  color: white; /* White text */
  border: none; /* Remove default border */
  border-radius: 8px; /* Rounded corners */
  font-size: 2rem; /* Increased font size for larger buttons */
  padding: 20px 40px; /* Larger padding for a better touch area */
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background-color: #ff1312; /* Red background on hover */
    transform: scale(1.05); /* Slight increase in size */
  }

  &:focus, &:active {
    outline: none; /* Remove focus outline */
    box-shadow: none; /* Remove Bootstrap's default focus shadow */
  }
`;

function Home() {
  return (
    <div>
      <StyledBanner>
        <div>
          <Row>
            <Col>
              <StyledHeading>Welcome to Text It, Search It!</StyledHeading>
              <StyledSubHeading>Tisi is a text-to-search AI service</StyledSubHeading>
              <StyledSubHeading>Receive instant answers to your queries, just a text msg away</StyledSubHeading>
             </Col>
          </Row>
          </div>
          <CardGroup>
  <Card>
    <Card.Img variant="top" src={HugeResults} style={{ maxWidth: '90%', height: 'auto', margin: '0 auto' }} />
    <Card.Body>
      <Card.Title style={{ fontFamily: 'Roboto Mono', fontSize: '1.5rem' }}>Tisi's Perfect For</Card.Title>
      <Card.Text style={{ fontFamily: 'Roboto', fontSize: '1rem' }}>
      Digital detoxers seeking a break from internet and smart tech.
      Tech minimalists who prefer simple searching.
      Old flip phone users without a browser and want to be able to query for web information.
      
      </Card.Text>
    </Card.Body>
  </Card>

  <Card>
    <Card.Img variant="top" src={AiCircle} style={{ maxWidth: '90%', height: 'auto', margin: '0 auto' }} />
    <Card.Body>
      <Card.Title style={{fontFamily: 'Roboto Mono', fontSize: '1.5rem' }}>How It Works</Card.Title>
      <Card.Text style={{ fontSize: '1rem' }}>
        Users tired of receiving endless search results and information from search engines can
         simply SMS msg their question to our 10DLC number and receive a concise and accurate SMS answer to their phone in seconds.
         </Card.Text>
    </Card.Body>
  </Card>

  <Card>
    <Card.Img variant="top" src={TisiCapabilities} style={{ maxWidth: '90%', height: 'auto', margin: '0 auto' }} />
    <Card.Body>
      <Card.Title style={{fontFamily: 'Roboto Mono', fontSize: '1.5rem' }}>What Can You Search For?</Card.Title>
      <Card.Text style={{ fontSize: '1rem' }}>It is just an sports scores, weather updates, breaking news to general knowledge - TISI has you covered.</Card.Text>
    </Card.Body>
  </Card>

  <Card>
    <Card.Img variant="top" src={TisiFlipPhone} style={{ maxWidth: '90%', height: 'auto', margin: '0 auto' }} />
    <Card.Body>
      <Card.Title style={{ fontFamily: 'Roboto Mono', fontSize: '1.5rem' }}>Get Started Now</Card.Title>
      <Card.Text style={{ fontSize: '1rem' }}>Experience the power of AI search without the digital clutter. Try TISI today!</Card.Text>
    </Card.Body>
  </Card>
</CardGroup>
        
              <div>
              <Stack direction="vertical" gap={4} className="col-md-5 mx-auto">
              <Button size="lg" variant='danger' style={{ padding: '0.75rem 1rem', fontSize: '1.25rem', marginBottom: '.25rem', marginTop: '2rem' }} as={RouterLink} to="/userauth">Login or Register</Button>
              </Stack>
              </div>
      
      </StyledBanner>
      <PerplexityChat />
    </div>
  );
}

export default Home;