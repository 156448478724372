// components/About.jsx
import React from 'react';
import NavMenu from './NavMenu';

const About = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>We are a company that specializes in providing AI-powered responses to text messages. Our goal is to make it easy for people to get the information they need without having to use a smartphone or a web browser.</p>
      <p>Our team consists of experienced developers and AI experts who are passionate about using technology to make people's lives easier.</p>
      <p>If you have any questions or feedback, please don't hesitate to contact us.</p>
    </div>
  );
};

export default About;