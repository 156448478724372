import React, { useState } from 'react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Create a styled div that centers its contents
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
// Create a styled version of the Bootstrap Container component
const StyledContainer = styled(Container)`
  max-width: 800px;
  padding: 30px;
  margin: 50px auto;
  background-color: #e6e6e6; // Neutral background for the container
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative; // Added for positioning the heading
`;

const StyledHeading = styled.h1`
  font-size: 2rem;
  color: #333333;
  text-align: center;
  padding: 20px 0;
  margin-top: -40px; // Adjust margin to position above the container
  background-color: #e6e6e6;
  border-bottom: 2px solid #dee2e6;
  width: 100%; // Full width
  border-radius: 10px 10px 0 0; // Rounded top corners
`;

const StyledFormLabel = styled(Form.Label)`
  font-size: 1.4rem;
  font-family: 'Roboto Mono', serif;
  margin: 1rem 0;
  color: #333; // Dark gray/black text for readability
`;
// Create a styled version of the Bootstrap Button component

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 1.5rem;
  padding: 10px;
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  background-color: #bfc0c0; 
  color: #333333;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: #dc3545;
  }
  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }
`;

const StyledErrorLabel = styled.label`
  font-size: 18px;
  font-weight: bold;
  color: #ff0000; // Standard red for errors
  margin: 5px 10px 10px 5px;
`;

const StyledResponseLabel = styled(Form.Label)`
  font-size: 1.1rem;
  margin: 20px auto 1.25rem auto; // Centered with auto margins
  color: #333; // Dark gray/black text
  background-color: #81fcd3; // Light blue/green color reminiscent of old cell phone screens
  border: 10px solid #333; // Nearly black border
  border-radius: 20px; // Rounded edges
  padding: 20px; // Padding inside the label
  font-family: 'Roboto', Courier, monospace; // Monospaced font for a more "digital" look
  font-weight: bold; // Bold font weight
  display: block; // Ensure it's a block to handle margins and padding correctly
  width: 70%; // Set width to 50% of its container
`;
const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; // Set a fixed height or adjust as needed
  width: 100%;
`;
const BlinkingText = styled.span`
  animation: blink-animation 1s steps(5, start) infinite;
  @keyframes blink-animation {
    90% { opacity: .5; }
  }
`;

const PerplexityChat = () => {
  const [inputValue, setInputValue] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false); // New state to track form submission

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setHasSubmitted(true); // Set hasSubmitted to true when the form is submitted
    handleButtonClick();
  };

  const handleButtonClick = () => {
    setIsLoading(true);
    const options = {
      method: 'POST',
      url: 'https://api.perplexity.ai/chat/completions',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer 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'
      },
      body: JSON.stringify({
        model: 'llama-3.1-sonar-small-128k-online',
        return_citations: false,
        messages: [
          { role: 'system', content: 'Be precise, use most recent online information, and keep responses to 150 characters or less.' },
          { role: 'user', content: inputValue },
        ]
      })
    };

    fetch(options.url, options)
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data && data.choices && data.choices.length > 0) {
          setError('');
          const cleanedResponse = removeBracketedNumbers(data.choices[0].message.content);
          setApiResponse(cleanedResponse);
        } else {
          setApiResponse('');
          setError('Sorry, we\'re having technical difficulties at this time.');
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
        setApiResponse('');
        setError(error.message);
      });
  };

  const removeBracketedNumbers = (text) => {
    return text.replace(/\[\d+\]/g, ''); // Removes any bracketed numbers
  };

  return (
    <CenteredDiv>
      <StyledContainer>
        <StyledHeading>How Tisi Works</StyledHeading>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <StyledFormLabel>Have a flip phone without web access? With Tisi's answers, you’ll get real-time precise answers sent by SMS msg directly to your phone. Just text in your question, 
              and Tisi does the searching for you, simulate a Tisi Q&A service below by asking anything you want.</StyledFormLabel>
            <Form.Control size="lg" type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Ask Tisi a question..." style={{ marginBottom: '20px' }} />
            <StyledButton type="submit" disabled={isLoading}>
              {isLoading ? <BlinkingText>Loading...</BlinkingText> : "Click for a Simulated Tisi SMS Msg"}
            </StyledButton>
            <StyledErrorLabel> {error}</StyledErrorLabel>
            {hasSubmitted && (
              <StyledResponseLabel>
                {isLoading ? <StyledSpinnerContainer>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </StyledSpinnerContainer> : (
                <ReactMarkdown>{apiResponse}</ReactMarkdown>
              )}
              </StyledResponseLabel>
            )}
          </Form.Group>
        </Form>
      </StyledContainer>
    </CenteredDiv>
  );
};

export default PerplexityChat;
