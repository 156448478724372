import React from 'react';
import { Container, Row, Col, Card, Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const StyledCard = styled.div`
  background-color: #e6e6e6;
  padding: 40px 200px;
  border-radius: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const StyledButton = styled(Button)`
  color: #e6e6e6;
  text-decoration: none;
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0 30px;
  &:hover {
    color: #ff1312;
  }
`;

const TermsOfService = () => {
  const navigate = useNavigate();

  const markdownContent = `
## Text It Search It Terms of Service

**Effective Date:** November 23, 2024

**Acceptance of Terms**
By using Text It Search It (aka "Tisi"), you agree to these Terms of Service. If you disagree with any part, please do not use our service.

**Service Description**
Tisi provides an SMS-based answer engine service, utilizing the Perplexity AI API to respond to user queries. We operate out of Reno, Nevada, using AWS Lambda and AWS Pinpoint with 10DLC phone numbers.

**User Eligibility**
You must be at least 13 years old to use Tisi. By using the service, you represent that you meet this requirement.

**Privacy Policy**
Our Privacy Policy, available [here](https://textitsearchit.com/privacy), explains how we collect, use, and protect your information.

**Service Usage**
You may use Tisi to submit queries via SMS and receive answers. You are responsible for all charges incurred through your use of Tisi, including message and data rates from your carrier. Tisi is provided "as is" without warranties of any kind.

**Prohibited Conduct**
You agree not to use Tisi for any unlawful purpose or in violation of any applicable laws.

**Intellectual Property**
All content provided through Tisi is protected by copyright and other intellectual property laws.

**Limitation of Liability**
To the fullest extent permitted by law, Tisi and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages.

**Indemnification**
You agree to indemnify and hold harmless Tisi and its affiliates from any claims resulting from your use of the service.

**Termination**
You may cancel the service at any time by texting "STOP" to Tisi or canceling your subscription at www.textitsearchit.com. Tisi reserves the right to terminate or suspend your access to the service at any time, without notice, for any reason.

**Changes to Terms**
Tisi may modify these Terms at any time. Continued use of the service after changes constitutes acceptance of the new Terms.

**Governing Law**
These Terms shall be governed by the laws of the State of Nevada, without regard to its conflict of law provisions.

**Contact Information**
For assistance, text "HELP" to Tisi or email [help@textitsearchit.com](mailto:help@textitsearchit.com).

**Carrier Liability**
Carriers are not liable for delayed or undelivered messages.

**Transparency**
Tisi uses Perplexity's API for generating responses. Currently, our service cannot provide source citations for the information provided.

**Accuracy Disclaimer**
The information provided by Tisi is generated by AI and may not always be 100% accurate or up-to-date. Users should exercise caution when relying on this information.

**User Responsibility**
Users are responsible for verifying important information independently, especially for critical decisions or academic purposes. Tisi should not be considered a substitute for professional advice or authoritative sources.

**Ongoing Development**
We are actively working on implementing citation features as they become available through the Perplexity API. We strive to continually improve our service to provide more accurate and verifiable information.

**Perplexity API Usage**
Tisi's use of the Perplexity API is subject to Perplexity's terms of service and any applicable licensing agreements. Users acknowledge that Perplexity may have access to query data as per their privacy policy.

**Acknowledgement**
By using Tisi, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
`;

  return (
    <StyledCard>
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="mt-5">
              <Card.Body>
                <ReactMarkdown>{markdownContent}</ReactMarkdown>
                <div>
                  <Stack direction="horizontal" gap={5} className="col-md-8">
                    <StyledButton size="lg" variant='danger' onClick={() => navigate('/privacy')}>
                      Read Privacy Policy
                    </StyledButton>
                    <StyledButton size="lg" variant='primary' onClick={() => navigate('/')}>
                      Go Home
                    </StyledButton>
                  </Stack>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </StyledCard>
  );
};

export default TermsOfService;
