// components/FAQ.jsx
import React from 'react';
import NavMenu from './NavMenu';

const FAQ = () => {
  return (
    <div>
      <h1>FAQ</h1>
      <h2>What is this service?</h2>
      <p>This service allows you to text a 10DLC phone number to receive responses from the Perplexity.ai API. This is useful for people who don't have access to a smartphone or a web browser.</p>
      <h2>How do I use this service?</h2>
      <p>Simply text your question to the 10DLC phone number, and you will receive a response from the Perplexity.ai API.</p>
      <h2>What is the cost of this service?</h2>
      <p>This service is free to use, but standard text messaging rates may apply.</p>
      <h2>What is Perplexity.ai?</h2>
      <p>Perplexity.ai is an AI-powered language model that can generate responses to natural language queries.</p>
      <h2>How do I cancel my account?</h2>
      <p>You can cancel your account by going to the profile page and clicking the "Cancel Account" button.</p>
    </div>
  );
};

export default FAQ;