import * as React from 'react';

function ServicePricing() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <stripe-pricing-table pricing-table-id="prctbl_1QSZxZ2M3FifjvBX370cOXca"
publishable-key="pk_test_51Ph3Yq2M3FifjvBXopug3P4hR9ACQWjX4z6PdP6k6YdfyFf2NHePUf7BlZjCDWl7pqKYd4aXpSzV0l8AI8b9EtHZ00dNKrfgKB">
</stripe-pricing-table>
  );
}

export default ServicePricing;