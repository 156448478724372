import React from 'react';
import { Routes, Route } from 'react-router-dom';
// aws cloud imports
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// react -bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
// components
import About from './components/About';
import FAQ from './components/FAQ';
import Profile from './components/Profile';
import UserAuth from './components/UserAuth';
import NavMenu from './components/NavMenu';
import Payment from './components/Payment';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import FooterMenu from './components/FooterMenu';
import ServicePricing from './components/ServicePricing';

Amplify.configure(awsExports);

function App() {
  
  const { authStatus } = useAuthenticator();

  return (
    <div>
      <NavMenu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/service-pricing" element={<ServicePricing />} />
        <Route path="/userauth" element={<UserAuth />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route
          path="/profile"
          element={authStatus === 'authenticated'? <Profile /> : <UserAuth /> }
        />
        <Route
          path="/payment"
          element={authStatus === 'authenticated'? <Payment /> : <UserAuth />}
        />
      </Routes>
      <FooterMenu />
      <div>
        <p className="footer">Copyright © 2024 TextItSearchIt from <a href='https://sleke.io'>Sleke.</a> All rights reserved.</p>
      </div>
    </div>
  );
}

const AppWithAuthenticator = () => {
  return (
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  );
};

export default AppWithAuthenticator;