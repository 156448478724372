import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Card, Form, Button, Alert, Modal, FormText} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faLock, faCreditCard} from '@fortawesome/free-solid-svg-icons';
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import {fetchUserAttributes, updateUserAttributes, signOut, confirmUserAttribute, updateUserAttribute, sendUserAttributeVerificationCode, currentAuthenticatedUser} from 'aws-amplify/auth';
import {updateUser} from "../graphql/mutations";
// import { Auth } from 'aws-amplify';


async function handleFetchUserAttributes() {
    try {
        const userAttributes = await fetchUserAttributes();
        console.log(userAttributes);
    } catch (error) {
        console.log(error);
    }
}


const Profile = () => {
    const [user, setUser] = useState({
        email: '',
        phone: '',
        username: ''
    });
    
    // Removed unnecessary console.log

    useEffect(() => {
         // Removed unnecessary console.log
        const fetchAndSetUserAttributes = async () => {
            try {
                const attributes = await fetchUserAttributes();
                console.log(attributes)
                setUser({
                    ...user,
                    email: attributes.email,
                    phone: attributes.phone_number,
                    username: attributes.sub
                    // Add other attributes as needed
                });
            } catch (error) {
                console.error("Failed to fetch user attributes:", error);
            }
        };

        fetchAndSetUserAttributes();
    }, []); // Empty dependency array means this effect runs once on mount

    const [emailEditing, setEmailEditing] = useState(false)
    const [emailOtpMode, setEmailOtpMode] = useState(false)
    const [otp, setOtp] = useState("")

    const handleEmailSaveClick = async () => {
        try {
            await updateUserAttributes({
                userAttributes: {
                    email: user.email,
                }
            })
            console.log("Verification sent");
            setEmailEditing(false)
            setEmailOtpMode(true)
            alert(`A verification code has been sent to ${user.email}. Please check your inbox and enter the 6-digit code below.`);
        } catch (error) {
            console.error("Failed to update user attributes:", error);
            setError("Failed to update email. Please try again or email us at help@textitsearchit.com");
            alert("Failed to update email. Please try again or email us at help@textitsearchit.com");
        }
    };

    const [phoneEditing, setPhoneEditing] = useState(false)
    const [phoneOtpMode, setPhoneOtpMode] = useState(false)
    
    const handlePhoneSaveClick = async () => {
        try {
            await updateUserAttributes({
                userAttributes: {
                    phone_number: user.phone,
                }
            })
            console.log("Verification sent");
            setPhoneEditing(false)
            setPhoneOtpMode(true)
            alert(`A verification code has been sent to ${user.phone}. Please check your inbox and enter the 6-digitcode below.`);
        } catch (error) {
            console.error("Failed to update user attributes:", error);
            setError("Failed to update your phone number. Please try again or email us at help@textitsearchit.com");
            alert("Failed to update your phone number. Please try again or email us at help@textitsearchit.com");

        }
    };

    // const resendVerificationCode = async (attributeKey) => {
    //     try {
    //         // Send verification code for the specific attribute
    //         console.log('inside resend verification, email is ', user.email)
    //         await sendUserAttributeVerificationCode({
    //             userAttributeKey: attributeKey
    //         });
    //         console.log(`Verification code sent to ${attributeKey}`);
    //         alert(`Verification code has been sent to your ${attributeKey}.`);
    //     } catch (error) {
    //         console.error('Error resending verification code:', error);
    //         alert('Failed to resend verification code. Please try again.');
    //     }
    // };

    const verifyOtp = async (attributeKey, otp) => {
        try {
            // Confirm the user attribute using the verification code (OTP)
            await confirmUserAttribute({
                userAttributeKey: attributeKey,
                confirmationCode: otp,
            });
            console.log(`${attributeKey} verified successfully!`);
            alert(`${attributeKey} verified successfully!`);
            if (attributeKey === 'email') {
                setEmailOtpMode(false)
            } else if (attributeKey === 'phone_number') {
                setPhoneOtpMode(false)
            }
        } catch (error) {
            console.error('Failed to verify OTP:', error);
            console.log("Error Code:", error.code);  // The specific error code (e.g., "CodeMismatchException")
            console.log("Error Message:", error.message);  // The error message
            console.log("Error Stack:", error.stack);  // The stack trace, if available
            setError("Failed to verify OTP. Please try again or email us at help@textitsearchit.com");
            alert("Failed to verify OTP. Please try again or email us at help@textitsearchit.com");

        }
    }

        const [error, setError] = useState(null);
        const handleCancelSubscriptionClick = () => {
            // Cancel subscription API call
        };


        return (
          <main className="profile-page">
          <Container fluid className="py-5">
            <Row>
              <Col md={8} className="mx-auto">
                <Card className="mb-4 profile-card">
                  <Card.Body>
                    <h2 className="mb-3">Account Information</h2>
                    <div>
                      <p><strong>Username:</strong> {user.username}</p>
                      <p><strong>Email:</strong> {user.email}</p>
                      <p><strong>Phone Number:</strong> {user.phone}</p>
                    </div>
                  </Card.Body>
                </Card>

                            <Card className="mb-4">
                                <Card.Body>
                                    <h4 className="mb-3" style={{color: '#333'}}>
                                        Email Address
                                    </h4>
                                    {emailEditing ? (
                                        <Form>
                                            <Form.Group controlId="newEmail">
                                                <Form.Label> Please Enter Your New Email Address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={user.email}
                                                    onChange={(e) => setUser({...user, email: e.target.value})}/>

                                            </Form.Group>
                                            <Button variant="primary" onClick={handleEmailSaveClick} style={{ marginTop: '10px' }}>
                                                Submit
                                            </Button>
                                            <Button variant="secondary" onClick={() => setEmailEditing(false)} style={{ marginTop: '10px', marginLeft: '10px' }}>
                                                Cancel
                                            </Button>
                                        </Form>
                                    ) : emailOtpMode ? (
                                            <Form>
                                                <Form.Group controlId="otp">
                                                    <Form.Label>Enter the OTP Passcode that was sent your new email address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={otp}
                                                        onChange={(e) => setOtp(e.target.value)}
                                                    />
                                                </Form.Group>
                                                <Button variant="primary" onClick={() => verifyOtp('email', otp)} style={{ marginTop: '10px' }}>
                                                    Verify
                                                </Button>
                                                <Button variant="secondary" onClick={() => setEmailOtpMode(false)} style={{ marginTop: '10px', marginLeft: '10px' }}>
                                                Cancel
                                            </Button>
                                            </Form>

                                        ) : (
                                            <div>
  
                                               <p>{user.email}</p> 
                                                <Button variant="primary" onClick={() => setEmailEditing(true)}>
                                                    <FontAwesomeIcon icon={faEdit}/> Edit
                                                </Button>
                                            </div>
                                        )}
                                </Card.Body>
                            </Card>

                            <Card className="mb-4">
                                <Card.Body>
                                    <h4 className="mb-3" style={{color: '#333'}}>
                                        Phone Number
                                    </h4>
                                    {phoneEditing ? (
                                        <Form>
                                            <Form.Group controlId="newPhone">
                                                <Form.Label>Enter Your New Phone Number Using this Format: +1234567890</Form.Label>
                                                <Form.Control
                                                    type="phone"
                                                    value={user.phone}
                                                    onChange={(e) => setUser({...user, phone: e.target.value})}/>
                                            </Form.Group>
                                            <Button variant="primary" onClick={handlePhoneSaveClick} style={{ marginTop: '10px' }}>
                                                Submit
                                            </Button>
                                            <Button variant="secondary" onClick={() => setPhoneEditing(false)} style={{ marginTop: '10px', marginLeft: '10px' }}>
                                                Cancel
                                            </Button>
                                        </Form>
                                    ) : phoneOtpMode ? (
                                        <Form>
                                            <Form.Group controlId="otp">
                                                <Form.Label>Enter the OTP passcode that was sent to your phone number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Button variant="primary" onClick={() => verifyOtp('phone_number', otp)} style={{ marginTop: '10px' }}>
                                                Verify
                                            </Button>
                                            <Button variant="secondary" onClick={() => setPhoneOtpMode(false)} style={{ marginTop: '10px', marginLeft: '10px' }}>
                                                Cancel
                                            </Button>
                                        </Form>

                                    ) : (
                                        <div>
                                            <p>
                                                 {user.phone}
                                            </p>
                                            <Button variant="primary" onClick={() => setPhoneEditing(true)} style={{ marginTop: '10px' }}>
                                                <FontAwesomeIcon icon={faEdit}/> Edit
                                            </Button>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>

                            <Card className="mb-4">
                                <Card.Body>
                                    <h4 className="mb-3" style={{color: '#333'}}>
                                         Update Subscription
                                    </h4>
                                    <p>
                                        You are currently subscribed to our service.
                                    </p>
                                    <Button variant="danger" style={{ marginRight: '10px', marginTop: '10px' }}>
                                        Cancel Subscription
                                    </Button>
                                    <Button variant="danger" style={{ marginTop: '10px' }}>
                                        Update Subscription
                                    </Button>
                                </Card.Body>
                            </Card>

                            <Card className="mb-4">
                                <Card.Body>
                                    <h4 className="mb-3" style={{ marginTop: '10px', color: '#333'}}>
                                        Sign Out
                                    </h4>
                                    <p>
                                        Click the button below to sign out of your account.
                                    </p>
                                    <Button variant="primary" onClick={signOut}>
                                        Sign Out
                                    </Button>
                                </Card.Body>
                            </Card>

                            {error && (
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Container>
            </main>

        )
    }

export default Profile;