/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Service } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ServiceCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    description: "",
    monthlyPrice: "",
    annualPrice: "",
    monthlyQueryLimit: "",
    annualQueryLimit: "",
  };
  const [name, setName] = React.useState(initialValues.name);
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [monthlyPrice, setMonthlyPrice] = React.useState(
    initialValues.monthlyPrice
  );
  const [annualPrice, setAnnualPrice] = React.useState(
    initialValues.annualPrice
  );
  const [monthlyQueryLimit, setMonthlyQueryLimit] = React.useState(
    initialValues.monthlyQueryLimit
  );
  const [annualQueryLimit, setAnnualQueryLimit] = React.useState(
    initialValues.annualQueryLimit
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setDescription(initialValues.description);
    setMonthlyPrice(initialValues.monthlyPrice);
    setAnnualPrice(initialValues.annualPrice);
    setMonthlyQueryLimit(initialValues.monthlyQueryLimit);
    setAnnualQueryLimit(initialValues.annualQueryLimit);
    setErrors({});
  };
  const validations = {
    name: [{ type: "Required" }],
    description: [],
    monthlyPrice: [{ type: "Required" }],
    annualPrice: [{ type: "Required" }],
    monthlyQueryLimit: [{ type: "Required" }],
    annualQueryLimit: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          description,
          monthlyPrice,
          annualPrice,
          monthlyQueryLimit,
          annualQueryLimit,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new Service(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ServiceCreateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              description,
              monthlyPrice,
              annualPrice,
              monthlyQueryLimit,
              annualQueryLimit,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description: value,
              monthlyPrice,
              annualPrice,
              monthlyQueryLimit,
              annualQueryLimit,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextField>
      <TextField
        label="Monthly price"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={monthlyPrice}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              description,
              monthlyPrice: value,
              annualPrice,
              monthlyQueryLimit,
              annualQueryLimit,
            };
            const result = onChange(modelFields);
            value = result?.monthlyPrice ?? value;
          }
          if (errors.monthlyPrice?.hasError) {
            runValidationTasks("monthlyPrice", value);
          }
          setMonthlyPrice(value);
        }}
        onBlur={() => runValidationTasks("monthlyPrice", monthlyPrice)}
        errorMessage={errors.monthlyPrice?.errorMessage}
        hasError={errors.monthlyPrice?.hasError}
        {...getOverrideProps(overrides, "monthlyPrice")}
      ></TextField>
      <TextField
        label="Annual price"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={annualPrice}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              description,
              monthlyPrice,
              annualPrice: value,
              monthlyQueryLimit,
              annualQueryLimit,
            };
            const result = onChange(modelFields);
            value = result?.annualPrice ?? value;
          }
          if (errors.annualPrice?.hasError) {
            runValidationTasks("annualPrice", value);
          }
          setAnnualPrice(value);
        }}
        onBlur={() => runValidationTasks("annualPrice", annualPrice)}
        errorMessage={errors.annualPrice?.errorMessage}
        hasError={errors.annualPrice?.hasError}
        {...getOverrideProps(overrides, "annualPrice")}
      ></TextField>
      <TextField
        label="Monthly query limit"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={monthlyQueryLimit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              description,
              monthlyPrice,
              annualPrice,
              monthlyQueryLimit: value,
              annualQueryLimit,
            };
            const result = onChange(modelFields);
            value = result?.monthlyQueryLimit ?? value;
          }
          if (errors.monthlyQueryLimit?.hasError) {
            runValidationTasks("monthlyQueryLimit", value);
          }
          setMonthlyQueryLimit(value);
        }}
        onBlur={() =>
          runValidationTasks("monthlyQueryLimit", monthlyQueryLimit)
        }
        errorMessage={errors.monthlyQueryLimit?.errorMessage}
        hasError={errors.monthlyQueryLimit?.hasError}
        {...getOverrideProps(overrides, "monthlyQueryLimit")}
      ></TextField>
      <TextField
        label="Annual query limit"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={annualQueryLimit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              description,
              monthlyPrice,
              annualPrice,
              monthlyQueryLimit,
              annualQueryLimit: value,
            };
            const result = onChange(modelFields);
            value = result?.annualQueryLimit ?? value;
          }
          if (errors.annualQueryLimit?.hasError) {
            runValidationTasks("annualQueryLimit", value);
          }
          setAnnualQueryLimit(value);
        }}
        onBlur={() => runValidationTasks("annualQueryLimit", annualQueryLimit)}
        errorMessage={errors.annualQueryLimit?.errorMessage}
        hasError={errors.annualQueryLimit?.hasError}
        {...getOverrideProps(overrides, "annualQueryLimit")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
