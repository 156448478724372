import React from 'react';
import { Container, Row, Col, Card, Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledCard = styled.div`
  background-color: #e6e6e6; /* Lighter shade for a professional look */
  padding: 40px 200px;
  border-radius: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <StyledCard>
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="mt-5">
              <Card.Body>
                <Card.Title className="text-center">
                  <h1><strong>Text It Search It Privacy Policy</strong></h1>
                </Card.Title>
                <Card.Text>
                  <h4>Introduction</h4>
                  Welcome to TextIt SearchIt. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile messaging service. Please read this policy carefully to understand our practices regarding your information and how we will treat it.
                </Card.Text>
                <Card.Text>
                  <h4>Information We Collect</h4>
                  <h5><u>Personal Information</u></h5>
                  We collect only your phone number, email address, name, payment information, and the number of queries you make per month. We <strong>DO NOT</strong> collect or store the content of your queries or the answers provided.
                </Card.Text>
                <Card.Text>
                  <h4>Purpose of Data Collection</h4>
                  The phone number is used solely for sending and receiving SMS messages, while the query count is used for billing purposes and service improvement.
                </Card.Text>
                <Card.Text>
                  <h4>How We Use Your Information</h4>
                  We use the information we collect to:
                  <ul>
                    <li>Provide, operate, and maintain our mobile messaging service.</li>
                    <li>Respond to your queries and provide you with the requested information.</li>
                    <li>Improve our service and develop new features.</li>
                    <li>Monitor and analyze usage and trends to improve your experience.</li>
                    <li>Prevent fraudulent activity and ensure the security of our service.</li>
                  </ul>
                </Card.Text>
                <Card.Text>
                  <h4>Disclosure of Your Information</h4>
                  We do not share your information with third parties. The only information collected is your phone number and the number of queries made per month. None of the content from your queries or query answers will be collected.
                </Card.Text>
                <Card.Text>
                  <h4>User Consent</h4>
                  By providing your phone number, you consent to receive SMS messages from our service. You can opt-in by signing up for our service and opt-out at any time by replying "STOP" to any SMS message.
                </Card.Text>
                <Card.Text>
                  <h4>Data Security</h4>
                  We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction. This includes encryption and access controls. However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.
                </Card.Text>
                <Card.Text>
                  <h4>User Rights</h4>
                  You have the right to access, modify, or delete your personal information (your phone number and query count). To exercise these rights, please email us at <a href="mailto:help@textitsearchit.com">help@textitsearchit.com</a>.
                </Card.Text>
                <Card.Text>
                  <h4>California Online Privacy Protection Act (CalOPPA) Compliance</h4>
                  If you are a California resident, you have the right to request information about the categories of personal information we collect and the purposes for which we use that information.
                </Card.Text>
                <Card.Text>
                  <h4>Children’s Privacy</h4>
                  Our service is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
                </Card.Text>
                <Card.Text>
                  <h4>Changes to This Privacy Policy</h4>
                  We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes. Your continued use of the service after any modifications to the Privacy Policy constitutes your acceptance of the updated terms.
                </Card.Text>
                <Card.Text>
                  <h4>Contact Us</h4>
                  If you have any questions about our Privacy Policy, please contact us at <a href="mailto:help@textitsearchit.com">help@textitsearchit.com</a>.
                </Card.Text>
                <Card.Text>
                  <strong>Effective Date: 5/24/2024</strong>
                </Card.Text>
                <Card.Text>
                  <h6>Thank you for using TextIt SearchIt! Your privacy is important to us, and we are committed to protecting your personal information.</h6>
                </Card.Text>
                <div>
                  <Stack direction="horizontal" gap={5} className="col-md-8">
                    <Button size="lg" variant='danger' style={{ padding: '0.5rem 1rem', fontSize: '1.25rem', marginBottom: '.25rem', marginTop: '2rem' }} className="nav-link" onClick={() => navigate('/terms')}>
                      Terms of Service
                    </Button>
                    <Button size="lg" variant='outline-danger' style={{ padding: '0.5rem 1rem', fontSize: '1.25rem', marginBottom: '.25rem', marginTop: '2rem' }} className="nav-link" onClick={() => navigate('/')}>
                      Go Home
                    </Button>
                  </Stack>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </StyledCard>
  );
};

export default PrivacyPolicy;
