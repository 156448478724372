import React, { useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import {Amplify, API, graphqlOperation} from 'aws-amplify'; 
import styled from 'styled-components'; 
import { createUser } from '../graphql/mutations'; // Import the createUser mutation
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
Amplify.configure(awsExports);

// Define a styled div for spacing
const AuthenticatorWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 200px;
`;

/**
 * UserAuth component
 *a
 * Handles user auth functionality
 *
 * @returns {JSX.Element} The AWS Amplify Default Auth component
 */
export default function UserAuth() {
  const handleSignUp = async (formData) => {
    const {username, attributes} = formData;
    const {email, phone_number} = attributes;
  }
    // Create user in DynamoDB


  return (
    <AuthenticatorWrapper>
    <Authenticator
      onSignUp={handleSignUp}
      signUpAttributes={['email', 'phone_number']}
    >
      {({ signOut, user }) => (
        <main>
          <h1>Hello </h1>
          <Button variant='danger' style={{ width: '10%', padding: '0.75rem', fontSize: '1rem', margin: '1.25rem 1.25rem' }} onClick={signOut}>Sign out</Button>
        </main>
      )}
    </Authenticator>
    </AuthenticatorWrapper>
  );
 }
