import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Navbar, Button } from 'react-bootstrap';
import logo from '../assets/tisi-logo-drk-pix.png';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose';


// Define the styled toggle button
const StyledToggleButton = styled(Navbar.Toggle)`
  &:hover {
    span {
      color: #333; // Change icon color on hover
    }
  }
`;

const StyledNavMenu = styled.div`
  .nav-link {
    color: #e6e6e6;
    text-decoration: none;
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0 30px;
    &:hover {
      color: #ff1312;
    }
  }


  img {
    width: 85px;
    height: 85px;
    margin: 0 60px;
  }

  background-color: #333;

  @media (max-width: 768px) {
  /* Change hover color of the hamburger icon */
.navbar-toggler:hover .navbar-toggler-icon {
  filter: brightness(0) invert(0.3); /* Example for an SVG icon or CSS-styled icon */
  /* Or use background-color: #333; if it's an image */
}
/* Space out Nav.Link items in the mobile menu */
.navbar-collapse .nav-link {
  margin: 10px 0; /* Adjust spacing as needed */
}

/* Adjustments for the Login/Signup button */
.navbar-collapse .btn {
  width: 80%; /* Adjust width as needed */
  margin-left: auto; /* Align to the left */
  margin-right: auto; /* Ensure it's centered within its container if needed */
}

    img {
      margin: 0 5px;
    }
    .nav-link {
      margin: 25px 10px;
    }
  }
`;

export default function NavMenu(props) {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator();
  const [expanded, setExpanded] = useState(false); // State to manage navbar expansion

  return (
    <header>
      <StyledNavMenu>
        <Navbar expand="lg" data-bs-theme="dark" className="justify-content-between" sticky="top" expanded={expanded}>
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={logo} className="d-inline-block align-top" alt="TISI Logo" />
            </Navbar.Brand>
            <StyledToggleButton aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}>
            {expanded ? (
              <span><FontAwesomeIcon icon={faClose}/> </span> // Your close icon here
            ) : (
              <span><FontAwesomeIcon icon={faBars}/></span> // Your hamburger icon here
            )}
          </StyledToggleButton>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/" className="nav-link">Home</Nav.Link>
                <Nav.Link href="/service-pricing" className="nav-link">Services</Nav.Link>
                <Nav.Link href="/about" className="nav-link">About</Nav.Link>
                <Nav.Link href="/faq" className="nav-link">FAQ</Nav.Link>
              </Nav>
              <Nav className="ms-auto">
                {authStatus === 'configuring' && 'Loading...'}
                {authStatus !== 'authenticated' ? (
                  <Button variant="secondary" className="nav-link" onClick={() => navigate('/userauth')}>Login or Register</Button>
                ) : (
                  <Button variant="primary-outline" className="nav-link" onClick={() => navigate('/profile')}>My Account</Button>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </StyledNavMenu>
    </header>
  );
}