import React, { useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { Stripe, CardElement } from '@stripe/react-stripe-js';
import ServiceCreateForm from '../ui-components/ServiceCreateForm';

const PaymentForm = () => {
  // const [cardNumber, setCardNumber] = useState('');
  // const [expirationDate, setExpirationDate] = useState('');
  // const [cvc, setCvc] = useState('');
  // const [paymentMethod, setPaymentMethod] = useState(null);
  // const [error, setError] = useState(null);

  // const stripe = Stripe('YOUR_STRIPE_PUBLISHABLE_KEY');

  // const handleCardNumberChange = (event) => {
  //   setCardNumber(event.target.value);
  // };

  // const handleExpirationDateChange = (event) => {
  //   setExpirationDate(event.target.value);
  // };

  // const handleCvcChange = (event) => {
  //   setCvc(event.target.value);
  // };

  // const handlePaymentMethodChange = (event) => {
  //   setPaymentMethod(event.target.value);
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const cardElement = document.getElementById('card-element');
  //     const { paymentMethod: newPaymentMethod, error: stripeError } = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: cardElement,
  //       billing_details: {
  //         name: 'John Doe',
  //       },
  //     });

  //     if (stripeError) {
  //       setError(stripeError.message);
  //     } else {
  //       setPaymentMethod(newPaymentMethod);
  //       // Send the payment method ID to your server-side API to create a payment intent
  //       // ...
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // };

  return (
    <ServiceCreateForm />
    // <Card>
    //   <Card.Body>
    //     <Form onSubmit={handleSubmit}>
    //       <Row>
    //         <Col md={6}>
    //           <Form.Group controlId="cardNumber">
    //             <Form.Label>Card Number</Form.Label>
    //             <Form.Control
    //               type="text"
    //               value={cardNumber}
    //               onChange={handleCardNumberChange}
    //               placeholder="Card Number"
    //             />
    //           </Form.Group>
    //         </Col>
    //         <Col md={6}>
    //           <Form.Group controlId="expirationDate">
    //             <Form.Label>Expiration Date</Form.Label>
    //             <Form.Control
    //               type="text"
    //               value={expirationDate}
    //               onChange={handleExpirationDateChange}
    //               placeholder="MM/YY"
    //             />
    //           </Form.Group>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col md={6}>
    //           <Form.Group controlId="cvc">
    //             <Form.Label>CVC</Form.Label>
    //             <Form.Control
    //               type="text"
    //               value={cvc}
    //               onChange={handleCvcChange}
    //               placeholder="CVC"
    //             />
    //           </Form.Group>
    //         </Col>
    //       </Row>
    //       <Button variant="primary" type="submit">
    //         Pay
    //       </Button>
    //       {error && (
    //         <div className="text-danger">
    //           <small>{error}</small>
    //         </div>
    //       )}
    //     </Form>
    //   </Card.Body>
    // </Card>
  );
};

export default PaymentForm;